
import Vue from "vue";

import {
  billingChangeRequestMapState,
  billingChangeRequestMapActions,
  billingChangeRequestMapMutations
} from "@/store/modules/billingchangerequest";
import EditBillingChangeRequest from "@/views/shared/BillingChangeRequest/SingleBillingChangeRequests/EditBillingChangeRequest.vue";

interface Idata {
  didInit: boolean;
  activeIndex: string;
  error: string;
  message: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
}

export default Vue.extend({
  name: "SingleBillingChangeRequest",
  components: {
    EditBillingChangeRequest
  },
  data(): Idata {
    return {
      didInit: false,
      activeIndex: "info",
      error: "",
      message: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The Cancellation change request you are looking for cannot be found."
    };
  },
  created() {
    this.getBillingChangeRequest(this.billingChangeRequestId)
      .then(() => {})
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      })
      .finally(() => {
        this.didInit = true;
      });
  },
  destroyed() {
    this.SET_STATE({ key: "editing", value: null });
  },
  methods: {
    ...billingChangeRequestMapActions(["getBillingChangeRequest"]),
    ...billingChangeRequestMapMutations(["SET_STATE"])
  },
  computed: {
    ...billingChangeRequestMapState(["makingApiRequest", "editing"]),
    billingChangeRequestId(): string {
      return this.$route.params.billingchangerequest_id;
    }
  }
});
