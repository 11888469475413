import {
  useFormSection,
  useFormFieldGroup,
  useField
} from "@/components/FormBuilder/Helpers";

export default [
  ...useFormSection(
    [
      //   {
      //     element: "html",
      //     id: "sectionOne",
      //     tagName: "div",
      //     classList: ["mb-8 -mt-4"],
      //     children: [
      //       {
      //         element: "html",
      //         id: "headerOne",
      //         tagName: "h3",
      //         innerText: "",
      //         key: "insured.firstName",
      //         classList: ["uppercase", "text-base", "mb-4"]
      //       }
      //     ]
      //   },
      ...useFormFieldGroup(
        [
          useField({
            key: "insured.lastName",
            label: "Last Name",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.firstName",
            label: "First Name",
            placeholder: "",
            type: "dataview"
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "insured.insuredAddress.houseNumber",
            label: "House number",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.insuredAddress.streetName",
            label: "Street",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.insuredAddress.city",
            label: "City",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.insuredAddress.zipCode",
            label: "Zip",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.insuredAddress.state",
            label: "State",
            placeholder: "",
            type: "dataview"
          })
        ],
        { layout: "2-col" }
      )
    ],
    { title: "Insured Information" }
  ),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "insured.location.houseNumber",
            label: "House number",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.location.streetName",
            label: "Street",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.location.city",
            label: "City",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.location.zipCode",
            label: "Zip",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "insured.location.state",
            label: "State",
            placeholder: "",
            type: "dataview"
          })
        ],
        { layout: "2-col" }
      )
    ],
    { title: "Location" }
  )
];
